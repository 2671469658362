.animated-heading {	
    opacity: 0;	
    transform: translateY(20px);	
    animation: fadeInUp 2s ease forwards;	
  }	
  .animated-emoji {	
    opacity: 0;	
    animation: fadeInUpEmoji 4s ease forwards;	
  }	
  @keyframes fadeInUp {	
    from {	
      opacity: 0;	
      transform: translateY(20px);	
    }	

    to {	
      opacity: 3;	
      transform: translateY(0);	
    }	
  }	
  @keyframes fadeInUpEmoji {	
    from {	
      opacity: 0;	
    }	

    to {	
      opacity: 1;	
    }	
  }	

